import React, { useEffect, useRef, useState, useContext } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Sorocaba, Descricao } from '..';
import logo from '../../assets/logo/logoCaprem.png'

import casal from '../../assets/static/casal.png'
import quero500 from '../../assets/cta/quero500.png'


export default function Page(props) {
  const btnScrollTop = useRef(null)
  const [showBtn, setShowBtn] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [window.innerWidth])

  const handleScroll = (event) => {
    if (window.pageYOffset >= 700 && !showBtn) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  const opts = {
    height: "320",
    width: '470',
    playerVars: {
      modestbranding: 1,
    },
  };
  return (
    isMobile ?
    <ScrollableAnchor id={''}>
      <div className="flex w-full bg-topo flex-col">
        <div className="flex-auto">
          <img src={logo} className="flex menu-logo pt-6 pl-10" width="170" />

          <div className="flex  flex-col pl-6">
            <div className="flex pt-10">
              <div className="flex flex-col">
                <div className="tituloTopo">Indique e Ganhe
                </div>
                <div className="descricaoTopo pt-4"><strong>Ganhe R$ 500</strong> para usar<br /> como quiser! Comece <br />indicando agora
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center flex-col items-center">
            <img src={casal} className="imgVideo" />
          </div>
          <button onClick={() => document.getElementById("target").scrollIntoView({behavior: 'smooth'})}>
            <div className="flex justify-center flex-col items-center">
              <img src={quero500} className="flex imgCTA" />
            </div>
          </button>
        </div>
        <Descricao />
      </div>
      </ScrollableAnchor>
      :
      <ScrollableAnchor id={''}>
      <div className="flex w-full bg-topo flex-col">
      <div className="flex-auto relative">
        <div className="flex  flex-col pl-20 pt-10 absolute">
          <div className="flex pt-20">
            <div className="flex flex-col">
              <div className="flex tituloTopo font-big">Indique<br /> e Ganhe
              </div>
              <div className="descricaoTopo pt-4 font-bold"><strong>Ganhe R$ 500</strong> para usar<br /> como quiser! Comece <br />indicando agora
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center flex-col items-center">
          <img src={casal} className="imgVideo" />
        </div>
        <button onClick={() => document.getElementById("target").scrollIntoView({behavior: 'smooth'})}>
          <div className="flex justify-center flex-col items-center">
            <img src={quero500} className="flex imgCTA" />
          </div>
        </button>
      </div>
      <Sorocaba />
    </div>
      </ScrollableAnchor>
  )
}





