import styled from "styled-components";
import React, { useEffect, useRef, useState } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import logo from '../../assets/logo/logoCaprem.png'
import { Topo } from '..';

// import { Fade } from 'react-reveal'
// default function Page(props) {
	
		const COLORS = {
		  primaryDark: "#115b4c",
		  primaryLight: "#B6EDC8",
		};
		
		const MenuLabel = styled.label`
		  position: fixed;
		  top: -1rem;
		  right: 0rem;
		  border-radius: 50%;
		  height: 7rem;
		  width: 7rem;
		  cursor: pointer;
		  z-index: 1000;
		  text-align: center;
		`;
		
		const NavBackground = styled.div`
		  position: fixed;
		  top: 6.5rem;
		  right: 6.5rem;
		  background-image: radial-gradient(
			${COLORS.primaryDark},
			${COLORS.primaryLight}
		  );
		  height: 6rem;
		  width: 6rem;
		  border-radius: 50%;
		  z-index: 600;
		  transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
		  transition: transform 0.8s;
		`;
		
		const Icon = styled.span`
		  position: relative;
		  background-color: ${(props) => (props.clicked ? "transparent" : "white")};
		  width: 3rem;
		  height: 2px;
		  display: inline-block;
		  margin-top: 3.5rem;
		  transition: all 0.3s;
		  &::before,
		  &::after {
			content: "";
			background-color: white;
			width: 3rem;
			height: 2px;
			display: inline-block;
			position: absolute;
			left: 0;
			transition: all 0.3s;
		  }
		  &::before {
			top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
			transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
		  }
		  &::after {
			top: ${(props) => (props.clicked ? "0" : "0.8rem")};
			transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
		  }
		  ${MenuLabel}:hover &::before {
			top: ${(props) => (props.clicked ? "0" : "-1rem")};
		  }
		  ${MenuLabel}:hover &::after {
			top: ${(props) => (props.clicked ? "0" : "1rem")};
		  }
		`;
		
		const Navigation = styled.nav`
		  height: 100vh;
		  position: fixed;
		  top: 0;
		  right: 0;
		  z-index: 600;
		  width: ${(props) => (props.clicked ? "100%" : "0")};
		  opacity: ${(props) => (props.clicked ? "1" : "0")};
		  transition: width 0.8s, opacity 0.8s;
		`;
		
		const List = styled.ul`
		  position: absolute;
		  list-style: none;
		  top: 35%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  text-align: center;
		  width: 100%;
		`;
		const ItemLink = styled.a`
		  display: inline-block;
		  font-size: 1.5rem;
		  font-weight: 300;
		  text-decoration: none;
		  color: ${COLORS.primaryLight};
		  padding: 1rem 2rem;
		  background-image: linear-gradient(
			120deg,
			transparent 0%,
			transparent 50%,
			#fff 50%
		  );
		  background-size: 240%;
		  transition: all 0.4s;
		  &:hover,
		  &:active {
			background-position: 100%;
			color: ${COLORS.primaryDark};
			transform: translateX(1rem);
		  }
		`;
		
		function HamburgerMenu() {

			const [isMobile, setMobile] = useState(false)
			useEffect(() => {
				if (window.innerWidth >= 992) {
					setMobile(false)
				} else {
					setMobile(true)
				}
			}, [window.innerWidth])
		
			
				


		  const [click, setClick] = useState(false);
		  const handleClick = () => setClick(!click);
		  return (
			isMobile ?			<>
			  <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
				<Icon clicked={click}>&nbsp;</Icon>
			  </MenuLabel>
			  <NavBackground clicked={click}>&nbsp;</NavBackground>
		
			  <Navigation clicked={click}>
				<List>
				  <li>
					<ItemLink onClick={handleClick} href="#descricao">
					  A Campanha
					</ItemLink>
				  </li>
				  <li>
					<ItemLink onClick={handleClick} href="#faq">
					Como Funciona
					</ItemLink>
				  </li>
				  <li>
					<ItemLink onClick={handleClick} href="#target">
					Se inscreva
					</ItemLink>
				  </li>
				</List>

			  </Navigation>
				<Topo />
			</>
			:
			<nav class="flex-auto topnav navbar-expand-sm fixed-top navbar-light relative">
			<div class="flex flex-wrap justify-around">
					<div class="flex flex-wrap justify-around absolute">
						<img src={logo} className="flex menu-logo py-6" width="180" />
						<div class="flex flex-wrap myLinks py-6 font-semibold">
							<a onClick={() => document.getElementById("descricao").scrollIntoView({behavior: 'smooth'})}>A Campanha</a>
							<a onClick={() => document.getElementById("faq").scrollIntoView({behavior: 'smooth'})}>Como Funciona</a>
							<a onClick={() => document.getElementById("target").scrollIntoView({behavior: 'smooth'})}>Se inscreva</a>
							<a onClick={() => document.getElementById("faq").scrollIntoView({behavior: 'smooth'})}>Faq</a>
						</div>
					</div>
				</div>
				<Topo />
				</nav>
				);
			};
			
			
		  
		  export default HamburgerMenu;
