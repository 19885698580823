import React, { useEffect, useRef, useState } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

import { Fade } from 'react-reveal'
import botao from '../../assets/cta/queroGanhar.png'



export default function Page(props) {
  const btnScrollTop = useRef(null)
  const [showBtn, setShowBtn] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = (event) => {
    if (window.pageYOffset >= 700 && !showBtn) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  return (
    <ScrollableAnchor id={'footer'}>

      <div className="flex relative justify-items-center flex-col pt-10 bg-footer content-center	items-center">
        <Fade>
          <div className="flex flex-auto flex-col items-center">
          <button onClick={() => document.getElementById("target").scrollIntoView({behavior: 'smooth'})} className="flex footerButton">
              <div className="flex justify-center pt-32">
                <img src={botao} className="flex buttonFooter pt-4" />
              </div>
            </button>
            <div className="footerText py-10">@CAPREM CONSTRUTORA - TODOS OS DIREITOS RESERVADOS  <br />
            </div>
          </div>
        </Fade>
      </div>
    </ScrollableAnchor>
  )
}

