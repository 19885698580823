import React from "react";
import { configureAnchors } from "react-scrollable-anchor";

import {
  Menu,
  Topo,
  Sorocaba,
  Descricao,
  Cadastro,
  Faq,
  Footer,
  // ModalForm
} from "../../components";

const Home_Page = () => {
  configureAnchors({ offset: -60, scrollDuration: 2000 });

  return (
    <div>
      <Menu />
      {/* <Topo /> */}
      {/* <Sorocaba /> */}
      {/* <Cadastro />  */}
      {/* <Descricao /> */}
      <Faq />
      <Footer />
    </div>
  );
};

export default Home_Page;
