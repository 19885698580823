import React, { useEffect, useRef, useState, useContext } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

import cadastroMobile from '../../assets/static/cadastroMobile.png'
import cadastro from '../../assets/static/cadastro.png'


export default function Page(props) {
    const btnScrollTop = useRef(null)
    const [showBtn, setShowBtn] = useState(false)

    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = (event) => {
        if (window.pageYOffset >= 700 && !showBtn) {
            setShowBtn(true)
        } else {
            setShowBtn(false)
        }
    }

    const [isMobile, setMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setMobile(false)
        } else {
            setMobile(true)
        }
    }, [window.innerWidth])

    return (
        isMobile ?
        <ScrollableAnchor id={'cadastro'}>
        <div className="flex w-full flex-col bg-cadastro">
                    <div className="flex flex-col items-center relative">
                        <div className="flex flex-col items-center place-content-center">
                            <div className="flex flex-col self-start cadastro">
                                <img src={cadastroMobile} className="flex imgcadastro" />
                            </div>
                            <div className="flex flex-col items-center absolute">
                                <div className="flex flex-col botao-cadastro items-center">formulário
                                </div>
                                <div id="target"></div>

                            </div>
                        </div>
                    </div>
                </div >
            </ScrollableAnchor>
            :
            <ScrollableAnchor id={'cadastro'}>
            <div className="flex w-full flex-col bg-cadastro">
                    <div className="flex flex-col items-center relative">
                        <div className="flex flex-col items-center place-content-center">
                            <div className="flex flex-col self-start cadastro">
                                <img src={cadastro} className="flex imgcadastro" />
                            </div>
                            <div className="flex flex-col items-center absolute">
                                <div className="flex flex-col botao-cadastro items-center">formulário
                                </div>
                                <div id="target"></div>
                            </div>
                        </div>
                    </div>
                </div >
            </ScrollableAnchor >
    )
}