import React, { useEffect, useRef, useState } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Descricao, Cadastro } from '..';

import sorocabaMobile from '../../assets/static/SorocabaMobile.png'
import sorocaba from '../../assets/static/Sorocaba.png';


export default function Page(props) {
    const btnScrollTop = useRef(null)
    const [showBtn, setShowBtn] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        console.log('MOBILE ::: ', isMobile);
    }, [])

    const handleScroll = (event) => {
        if (window.pageYOffset >= 700 && !showBtn) {
            setShowBtn(true)
        } else {
            setShowBtn(false)
        }
    }

    const [isMobile, setMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setMobile(false)
        } else {
            setMobile(true)
        }
    }, [window.innerWidth])

    return (
        isMobile ?
            <ScrollableAnchor id={''}>
                <div className="flex w-full flex-col">
                        <div className="flex-auto flex-col">
                            <div className="flex flex-col justify-center sorocaba">
                                <img src={sorocabaMobile} className="imgSorocaba" />
                                
                            </div>
                        </div>
                </div>
            </ScrollableAnchor>
            :
            <ScrollableAnchor id={''}>
                <div className="flex w-full flex-col pt-10">
                    <Descricao />
                    <div className="flex-auto flex-col">
                        <div className="flex justify-center">
                            <img src={sorocaba} className="flex imgSorocaba absolute" />
                        </div>
                    </div>
                    <Cadastro />

                </div>
            </ScrollableAnchor>
    )
}


