import React, { useEffect, useRef, useState } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Cadastro } from '..';
import { Sorocaba } from '..';


import setas from '../../assets/logo/SETAS.png'
import indicacao from '../../assets/cta/indicacao.png'
import confirmacao from '../../assets/cta/confirmacao.png'
import negocioFechado from '../../assets/cta/negocioFechado.png'
import cartaoPresente from '../../assets/cta/cartaoPresente.png'
import participar from '../../assets/cta/participar.png';


export default function Page(props) {
  const [isMobile, setMobile] = useState(false)
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [window.innerWidth])
  return (
    isMobile ?
      <ScrollableAnchor>
        <section id={'descricao'} className="flex flex-col montagem">
          <Sorocaba/>
        <div className="flex flex-col justify-center descricao bg-white">
            <div className="flex flex-col boxdescricao">
              <div className="flex justify-center flex-col items-center">
                  <img src={participar} className="flex imgParticipar" />
              </div>
              <div className="flex topoText justify-center">
                <img src={setas} className="flex py-4" />
              </div>
              <div className="flex flex-col justify-center px-10 pb-36">
                <div className="flex flex-col descricao-content">indicação
                </div>
                <div className="flex flex-col descricao-text text-center">
                  Fale sobre a CAPREM ou um empreendimento CAPREM para um amigo ou familiar e preencha o formulário abaixo
                </div>
                <div className="flex flex-col descricao-content pt-4">confirmação
                </div>
                <div className="flex flex-col descricao-text text-center">
                  Confirmada a indicação, será realizado contato com o indicado para que ele conheça os empreendimentos CAPREM.
                </div>
                <div className="flex flex-col descricao-content pt-4">negócio fechado
                </div>
                <div className="flex flex-col descricao-text text-center">
                  Se o indicado por você fechar contrato de compra de um apartamento CAPREM, você recebe R$ 500 pela indicação.
                </div>
                <div className="flex flex-col descricao-content pt-4">cartão presente
                </div>
                <div className="flex flex-col descricao-text text-center">
                  O prêmio será entregue por meio de um cartão presente, que será gerado especificamente para você. O cartão presente só terá validade até acabar os créditos.
                </div>
              </div>
            </div>

        </div>
          <Cadastro />
        </section>
      </ScrollableAnchor>
      :
      <ScrollableAnchor>
      <section id={'descricao'}>
        <div className="flex descricao">
            <div className="flex flex-col boxdescricao">
              <div className="flex flex-col descricao-box">
                <div className="flex topoText">
                  <img src={indicacao} className="flex pt-4" />
                </div>
                <div className="descricao-content">
                  <div className="descricao-text font-bold font-blue text-center">
                    Fale sobre a CAPREM ou um empreendimento CAPREM para um amigo ou familiar e preencha o formulário abaixo
                  </div>
                </div>
              </div>

              <div className="flex flex-col descricao-box">
                <div className="flex topoText">
                  <img src={confirmacao} className="flex pt-14" />
                </div>
                <div className="flex descricao-content">
                  <div className="descricao-text font-bold font-blue text-center">
                    Confirmada a indicação, será realizado contato com o indicado para que ele conheça os empreendimentos CAPREM.
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col descricaoBox">
              <div className="flex flex-col descricao-box pl-10">
                <div className="flex topoText">
                  <img src={negocioFechado} className="flex pt-4" />
                </div>
                <div className="descricao-content">
                  <div className="descricao-text font-bold font-blue text-center">
                    Se o indicado por você fechar contrato de compra de um apartamento CAPREM, você recebe R$ 500 pela indicação.
                  </div>
                </div>
              </div>

              <div className="flex flex-col descricao-box pl-10">
                <div className="flex topoText">
                  <img src={cartaoPresente} className="flex pt-14" />
                </div>
                <div className="descricao-content">
                  <div className="descricao-text font-bold font-blue text-center">
                    O prêmio será entregue por meio de um cartão presente, que será gerado especificamente para você. O cartão presente só terá validade até acabar os créditos.

                  </div>
                </div>
              </div>
            </div>
        </div>
        </section>
      </ScrollableAnchor>
  )
}
