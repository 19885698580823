import React, { useEffect, useRef, useState } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import { DownOutlined } from '@ant-design/icons';
import { Fade } from 'react-reveal'
import faq from '../../assets/logo/iconFaq.png'
import 'antd/dist/antd.css';
import { Collapse, Space } from 'antd';
import styled from 'styled-components';
const { Panel } = Collapse;


export default function Page(props) {
    const btnScrollTop = useRef(null)
    const [showBtn, setShowBtn] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = (event) => {
        if (window.pageYOffset >= 700 && !showBtn) {
            setShowBtn(true)
        } else {
            setShowBtn(false)
        }
    }
    const [isMobile, setMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setMobile(false)
        } else {
            setMobile(true)
        }
    }, [window.innerWidth])


    function callback(key) {
        console.log(key);
    }
    return (
        <ScrollableAnchor>
            <section id='faq'>
                <div className="flex flex-col bg-cadastro pb-20 items-center">
                    <div className="flex flex-col justify-items-center justify-center items-center">
                        <img src={faq} className="flex flex-col duvidas pb-10" />
                    </div>
                    <div className="flex w-full flex-col items-center justify-center w-full boxFaq pb-20">
                        <details class="collapse">
                            <summary class="flex title justify-center">Quais são os pré-requisitos para indicar uma pessoa no INDIQUE E GANHE?</summary>
                            <br />

                            <div class="description">Para se cadastrar no programa Indique e Ganhe, você precisa ser maior de 18 anos e ter um documento de identificação com foto e CPF.Lembrando que, pessoas jurídicas e colaboradores diretos e indiretos da CAPREM não podem participar.
                            </div>
                        </details>
                        <br />
                        <details class="collapse2">
                            <summary class="flex title2 justify-center">Quem eu posso indicar?</summary>
                            <div class="description2">Você poderá indicar qualquer pessoa adulta, maior de 18 anos, com interesse em adquirir um imóvel CAPREM.</div>
                        </details>
                        <br />
                        <details class="collapse">
                            <summary class="flex title justify-center">Existe uma quantidade mínima de indicações para permanecer no programa?</summary>
                            <div class="description">A princípio, <strong>não exigimos nenhuma quantidade mínima de indicações </strong>para se manter no programa. Mas lembre-se: quanto mais indicações fizer, mais chances você terá de receber sua bonificação ou comissão.
                            </div>
                        </details>
                        <br />
                        <details class="collapse">
                            <summary class="flex title justify-center">Ao indicar um amigo eu já recebo meu prêmio?</summary>
                            <div class="description">Não. O prêmio só será disponibilizado assim que o indicado (a) fechar o contrato de compra de um imóvel CAPREM.</div>
                        </details>
                        <br />
                        <details class="collapse">
                            <summary class="flex title justify-center">Onde posso conferir o regulamento completo?</summary>
                            <div class="description">O regulamento da promoção INDIQUE E GANHE CAPREM está disponibilizado abaixo:
                                Após preenchido o formulário, nossa equipe de atendimento entrará em contato com você, para confirmar a indicação. Confirmada a indicação, será realizado contato com o seu amigo e apresentado para ele todos os empreendimentos.

                                A indicação ficará disponível na timelime do cliente, podendo ser solicitada (por qualquer uma das partes) a qualquer momento, dentro de 180 dias.

                                A promoção será válida a partir do dia 27/09/2021, com término a definir (será divulgado pela CAPREM previamente).

                                O prazo de validade da indicação da promoção é de 180 dias; após esse período a pessoa deixará de participar, automaticamente, sem necessidade de prévio aviso.

                                Caso seu amigo realize a compra, após assinado o contrato e finalizado o processo, você receberá o prêmio no valor de R$500,00.

                                O prêmio será entregue por meio de um cartão presente, que será gerado especificamente para você.

                                Você pode indicar quantos amigos quiser, porém, mesmo que tenha mais de uma assinatura, os prêmios serão entregues no mesmo cartão.

                                O Cartão Presente só terá validade até acabar o crédito. O prêmio não poderá ser revertido em desconto na venda, ou entregue em mãos.
                            </div>
                        </details>
                    </div>
                </div>
            </section>
        </ScrollableAnchor>
    )
}




